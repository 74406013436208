import { ErrorModal, Flex, useDisclosure } from '@stars-ecom/shared-atoms-ui'
import {
  BlogPush,
  CollectionSpace,
  EventPush,
  ProductCarouselPush,
  ProductMasonryPush,
  TimedContent,
  TVProducts,
  WebsiteContext
} from '@stars-ecom/shared-organisms-ui'
import JsonLd from '@stars-ecom/shared-organisms-ui/src/utils/jsonLd'
import _, { get, orderBy, trim, uniqBy } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { lengthLimiter } from '../utils/metaTitleFormatter'
import { simplifyGQLResult } from '../utils/strapi'

const PushTypes = {
  TV_SHOW: 'TV_SHOW',
  BLOG: 'BLOG',
  PRODUCTS: 'PRODUCTS',
  CUSTOM: 'CUSTOM',
  PRODUCTS_CAROUSEL: 'PRODUCTS_CAROUSEL',
  PRODUCTS_MASONRY: 'PRODUCTS_MASONRY'
}
const env = process.env.GATSBY_API_WEBSITE
let telephone = ''
let email_dpo = ''

switch (env) {
  case 'Téléshopping':
    telephone = '09 72 72 00 12'
    email_dpo = 'dpo.teleshopping@selextra.fr'

    break
  case 'M6 Boutique':
    telephone = '09 74 75 66 66'
    email_dpo = 'dpo.m6boutique@selextra.fr'
    break
  case 'Euroshopping':
    telephone = '09 72 72 01 11'
    email_dpo = 'dpo.euroshopping@selextra.fr'
    break
}

const HomePage = (props) => {
  const { pageContext, location } = props
  const windowGlobal = typeof window !== 'undefined' && window
  const websiteContext = useContext(WebsiteContext)
  const { home, tvShows, tvShowSettings, blogPush } = pageContext
  const [jsonNotLoaded, setJsonNotLoaded] = useState(true)

  const orderPush = simplifyGQLResult(get(home, 'order_pushes'))
  const pushTypeList = _.map(orderPush[0]?.order_push?.push_types, 'push_type.type')

  useEffect(() => {
    windowGlobal && windowGlobal?.scrollTo({ top: 0 })
    if (location?.state?.resetPassword) {
      onOpenResetPasswordModal()
      setTimeout(() => {
        handleCloseModal()
      }, 8000)
    }

    setJsonNotLoaded(false)
  }, [])

  const sortFunc = (a, b) => {
    return pushTypeList.indexOf(a.type) - pushTypeList.indexOf(b.type)
  }

  const postProcessor = (c) => {
    // reorder by desc startDate
    const orderPushes = orderBy(c, ['startDate'], ['desc'])

    // delete duplicate if type 'BLOG' || 'TV_SHOW'
    const pushesMultiple = orderPushes.filter((push) => !['BLOG', 'TV_SHOW'].includes(push.type))
    const pushesToClean = orderPushes.filter((push) => ['BLOG', 'TV_SHOW'].includes(push.type))
    const pushesUnique = uniqBy(pushesToClean, 'type')
    const cleanPushes = [...pushesUnique, ...pushesMultiple]

    // reorder by order_push
    const reorderPushes = cleanPushes.sort(sortFunc)

    return reorderPushes
  }

  const {
    isOpen: isOpenResetPasswordModal,
    onOpen: onOpenResetPasswordModal,
    onClose: onCloseResetPasswordModal
  } = useDisclosure()

  const handleCloseModal = () => {
    onCloseResetPasswordModal()
    let refreshState = location?.state
    refreshState.resetPassword = false
    history.replaceState(refreshState, null)
  }

  const renderPush = (push, index) => {
    switch (push.type) {
      case PushTypes.TV_SHOW:
        return (
          <TVProducts
            key={`push_${index}`}
            push={push}
            position={`HOME_${index}`}
            tvShows={tvShows}
            tvShowSettings={tvShowSettings}
          />
        )
      case PushTypes.BLOG:
        return (
          <BlogPush
            key={`push_${index}`}
            push={push}
            blogPush={blogPush}
            position={`HOME_${index}`}
          />
        )
      case PushTypes.CUSTOM:
        return <EventPush key={`push_${index}`} push={push} position={`HOME_${index}`} />
      case PushTypes.PRODUCTS_MASONRY:
        return <ProductMasonryPush key={`push_${index}`} push={push} position={`HOME_${index}`} />
      case PushTypes.PRODUCTS_CAROUSEL:
        return <ProductCarouselPush key={`push_${index}`} push={push} position={`HOME_${index}`} />
      default:
        return null
    }
  }

  const jsonLdData = {
    '@context': 'https://schema.org/',
    '@type': 'OnlineStore',
    name: websiteContext?.title,
    legalName: 'Selextra',
    logo: websiteContext?.logo?.url,
    url: location.origin,
    description: home?.metaDescription,
    telephone: telephone,
    email: email_dpo,
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Studio 107 - 30/32 rue de Proudhon ',
      addressLocality: 'LA PLAINE SAINT-DENIS',
      addressCountry: 'FR',
      addressRegion: 'Ile-de-France',
      postalCode: '93210'
    },
    vatId: 'FR26977492123',
    iso6523Code: '977492123'
  }
  return (
    <Flex direction="column" w="100%">
      {jsonNotLoaded && <JsonLd jsonLdData={jsonLdData} />}
      <Helmet
        bodyAttributes={{
          class: 'chakra-ui-light'
        }}>
        <meta charSet="utf-8" />
        <title>{lengthLimiter(home?.metaTitle)}</title>
        <meta name="description" content={home?.metaDescription} />
        {trim(websiteContext?.websiteSettings?.googleSiteVerification) && (
          <meta
            name="google-site-verification"
            content={trim(websiteContext?.websiteSettings?.googleSiteVerification)}
          />
        )}
      </Helmet>
      <ErrorModal
        isOpen={isOpenResetPasswordModal}
        onOpen={onOpenResetPasswordModal}
        onClose={handleCloseModal}
        message="Votre mot de passe a bien été changé. Nous vous invitons à vous connecter à nouveau."
        icon="informationCercle"
        color={websiteContext?.mainColor}></ErrorModal>
      {TimedContent.filter(home?.pushes, { postProcessor })?.map(renderPush)}
      <CollectionSpace
        universeMobile={pageContext?.collections?.filter((c) => c.customFields.isMobileUniverse)}
      />
    </Flex>
  )
}

HomePage.pageType = 'home'

export default HomePage
